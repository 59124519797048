@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';
@import '../../../common/assets/scss/ma-controls';

.header {
  padding-bottom: 1.75rem;
  border-bottom: 1px solid $border-colour;
  margin-bottom: 1.5rem;
}

.content {
  padding-top: 1.25rem;
}

.summary-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.25rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }

  :global {
    .badge {
      margin-right: 2.5rem;
      align-self: center;
    }

    .ma-action-menu {
      margin-left: auto;
    }
  }
}

.summary-panel,
.spend-panel {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.summary-panel-header,
.spend-panel-header,
.details-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.5rem;

  h4 {
    @extend .subtitle-font;

    margin-bottom: 0;
    color: $primary-a;
  }
}

.summary-panel-header {
  span {
    font-size: $body-font-size;
    margin-left: 0.5rem;
  }

  .priority-high {
    color: $danger;
  }

  .priority-low {
    color: $success;
  }
}

.spend-panel-header {
  .controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
    column-gap: 0.75rem;
    row-gap: 0.5rem;
  }

  .expand-collapse-btn {
    padding: 0 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 10rem;
  }

  .add-btn {
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
}

.summary-panel-body,
.spend-panel-body,
.details-panel-body {
  font-size: $body-font-size;
  overflow-wrap: anywhere;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.summary-panel-body {
  .confidential {
    color: $warning;
    margin-right: 0.5rem;
  }

  .reason {
    h6 {
      @extend .subtitle-font;

      font-size: $body-font-size;
      color: $subtle;
    }
  }

  .purchase-order-links {
    margin-top: 1.3rem;

    div {
      display: inline-block;
      margin-left: 0.4em;
    }

    a {
      @extend .primary-link;

      margin-right: 1em;
    }
  }
}

.summary-panel-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: none;

  button {
    width: 12.5rem;
    min-width: fit-content;
    min-height: $ma-controls-min-height;
    margin: 0 1rem;
  }
}

.order-request-modal-row {
  :global(.invalid-feedback) {
    display: block;
  }

  .confidential {
    user-select: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: 1.25rem;
  }
}

.spend-category-warning {
  background-color: $white;
  border-radius: $border-radius-standard;
  padding: 1.25rem;
  margin-bottom: 2rem;

  &:global(.alert-warning) {
    border-color: $warning;

    .heading {
      font-family: $be-vietnam-pro;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 0;
      color: $warning;

      svg {
        vertical-align: baseline;
      }

      .icon {
        font-size: 1rem;
        margin-right: 1.25rem;
      }
    }
  }
}

.details-panel-body {
  .history-event {
    margin-bottom: 1rem;
  }
}

.address-history {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding-bottom: 1rem;
}

.delivery-info {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;

  .list {
    column-gap: 1.25rem;
    row-gap: 0.75rem;
    flex-grow: 1;

    dt {
      color: $subtle;
    }

    .warning {
      font-size: 0.75rem;
      color: $warning;
    }
  }

  .action-btn {
    display: flex;
    align-items: flex-start;
    min-width: 1rem;
    min-height: 1rem;
  }
}
